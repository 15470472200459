import axios from 'axios'
export default {
  methods: {
    rec_upload_final (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'upload_final_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_anexos (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'upload_anexos_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_escalamiento (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'escalamiento_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_gestion (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'gestion_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_gestion_otro (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'gestion_eliminar_otro/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_log (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'log_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'gestion_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_log_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'log_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_escalamiento_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'escalamiento_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_escalamiento_otro_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'escalamiento_otro_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_cierrefinal_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'cierrefinal_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    }

  }
}
